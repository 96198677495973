const remoteUtils = await import( 'AAPE_Sync/utilities' );

let run = async function () {

    async function getUserProperties() {
        if (window.THDCustomer && THDCustomer.default && typeof(THDCustomer.default.loadCustomerChatConfiguration) === 'function') {
            const data = await THDCustomer.default.loadCustomerChatConfiguration().catch((e) => {
                console.log(e);
                return false;
            });
            if (data && data.customerConfiguration) {
                const userProperties = {
                    userType: data.customerConfiguration.userType ? data.customerConfiguration.userType : 'default',
                    token: THDCustomer.default.jwt
                };
                return userProperties;

            }
            return;
       
        }
    }  

	let _pp = remoteUtils.getPublishPath();

       if ( _pp === "prod" || _pp === "b2bprod" ) {

           _aape.sprinklrAppID = "65d48a332e1cd41a769de5c1_app_100699388";

       } else  {

           _aape.sprinklrAppID = "65d3af345bfaf22a16aec56e_app_100699369";
        }


        let ddo = (window.digitalData ? window.digitalData : ''),
            userProperties = await getUserProperties ();
        window.sprChatSettings = window.sprChatSettings || {};
        window.sprChatSettings = {
            'appId': _aape.sprinklrAppID,
            'skin': 'MODERN',
            'scope': 'CONVERSATION',
            'landingScreen': 'LAST_CONVERSATION',
            userContext: {
                '_c_65b7dde5a5886b114782ce41': [document.URL],
                '_c_65b7d8baa5886b11477f402c': [ddo.site.businessType ? ddo.site.businessType.toString().toLowerCase() : 'b2c'],
                '_c_65b7dfd7a5886b1147844c8f': [ddo.page.pageInfo.pageName ? ddo.page.pageInfo.pageName.toLowerCase() : ''],
                '_c_65b7dda3a5886b1147829dbd': [ddo.page.pageInfo.pageType ? ddo.page.pageInfo.pageType.toLowerCase() : ''],
                '_c_65b7dfaba5886b1147842a6f': [ddo.page.category.subCategory2 ? ddo.page.category.subCategory2.toLowerCase() : ddo.page.category.subCategory1 ? ddo.page.category.subCategory1.toLowerCase() : ddo.page.category.primaryCategory ? ddo.page.category.primaryCategory.toLowerCase() : ''],
                '_c_60c24dd31f3023769c37fbb0': [ddo.user.localization.storeNumber ? ddo.user.localization.storeNumber : ''],
                '_c_66d7535fc17bfe53116a80af': [ddo.user.localization.userZip ? ddo.user.localization.userZip : ''],
                '_c_66e9e08d099f5a1b6a2615b9': [ddo.user.localization.storeZip ? ddo.user.localization.storeZip : ''],
                '_c_66e9e2ac6723725986d7d5f3': [ddo.product && Array.isArray(ddo.product) && ddo.product[0] && ddo.product[0].productInfo.sku && ddo.product[0].productInfo.sku ? ddo.product[0].productInfo.sku : ''],
                '_c_66ec5d4075ae1f33be32d88d': [_aape.svocid ? _aape.svocid : ''],
                '_c_66ec5df375ae1f33be331eb1': [userProperties && userProperties.token ? userProperties.token : ''],
                '_c_65bbbbcf69b8d7310003141b': [userProperties && userProperties.userType ? userProperties.userType : 'default']
            },
        };

        /* eslint-disable */
        (function() {
            var t = window,
                e = t.sprChat,
                a = e && !!e.loaded,
                n = document,
                r = function() {
                    r.m(arguments)
                };

            r.q = [], r.m = function(t) {
                r.q.push(t)
            }, t.sprChat = a ? e : r;

            var e2 = t.sprTeamChat,
                r2 = function() {
                    r2.m(arguments)
                };

            r2.q = [], r2.m = function(t) {
                r2.q.push(t)
            }, t.sprTeamChat = e2 ? e2 : r2;

            var o = function() {
                var e = n.createElement("script");
                e.type = "text/javascript",
                e.async = !0,
                e.src =  "https://prod2-live-chat.sprinklr.com/api/livechat/handshake/widget/" + t.sprChatSettings.appId;
                e.onerror = function() {
                    t.sprChat.loaded = !1
                },
                e.onload = function() {
                    t.sprChat.loaded = !0
                };
                var a = n.getElementsByTagName("script")[0];
                a.parentNode.insertBefore(e, a)
            };

            "function" == typeof e ? a ? e("update", t.sprChatSettings) : o() : "loading" !== n.readyState ? o() : n.addEventListener("DOMContentLoaded", o)
        })();

/* eslint-enabled */
  
  };
  
  export { run };